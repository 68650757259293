
import axios from '@/axios';
import { format, formatDistance, parseISO } from 'date-fns';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
  IonPage,
  IonLabel,
  IonButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'Child',
  components: {
    IonPage,
    IonLabel,
    IonButton,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();
    const childId = Number(route.params.id) || 0;
    const child = store.getters['user/childById'](childId);
    const age = function(age = '') {
      if (age === '') {
        return '';
      }

      try {
        return formatDistance(parseISO(age), new Date()) + ' old';
      } catch (err) {
        console.log(err);
      }
    };

    const dateFormatted = (date = '') => {
      try {
        return format(parseISO(date), 'hh:mm / MMMM d / yyyy');
      } catch (err) {
        return null;
      }
    };

    const hasAppointment = computed(() => dateFormatted(child.nextAppointment));

    const downloadCSV = async function() {
      const url = `wp-json/user/v2/child/${childId}`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${child.initials}-full-download.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    };

    return {
      chevronBackOutline,
      hasAppointment,
      downloadCSV,
      dateFormatted,
      age,
      child,
      childId
    };
  }
};
