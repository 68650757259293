<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link to="/profile" class="back-button">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">{{ child.initials }}</h2>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true">
      <ion-grid class="horizontally-centered">
        <ion-row v-if="hasAppointment">
          <ion-col>
            <h4 class="text-primary">Next appointment</h4>
            <h2 class="mb-0">{{ dateFormatted(child.nextAppointment) }}</h2>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-label>
              <ion-button
                expand="block"
                size="large"
                :router-link="`/profile/child/${childId}/edit#appointment`"
              >
                <span v-if="hasAppointment">Edit</span>
                <span v-else>Add</span>
                &nbsp;appointment
              </ion-button>
            </ion-label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-label>
              <ion-button
                expand="block"
                size="large"
                :router-link="`/profile/child/${childId}/calendar`"
              >
                Sleep diary
              </ion-button>
            </ion-label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-label>
              <ion-button
                expand="block"
                size="large"
                :router-link="`/profile/child/${childId}/edit`"
              >
                Edit child profile
              </ion-button>
            </ion-label>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ion-button color="tertiary" expand="block" size="large" @click.prevent="downloadCSV">
              Download all data
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <div class="ion-padding-top">
              <h4 class="text-primary">Additional information</h4>
              <h4>Age:</h4>
              ~{{ age(child.age) }}
            </div>

            <div class="ion-margin-top">
              <h4>Primary prognosis:</h4>
              {{ child.prognosis }}
            </div>

            <div class="ion-margin-top">
              <h4>Conditions:</h4>
              <div>{{ child.conditions.join(', ') }}</div>
            </div>

            <div class="ion-margin-top">
              <h4>Pain:</h4>
              {{ child.pain === '1' ? 'Yes' : 'No' }}
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import axios from '@/axios';
import { format, formatDistance, parseISO } from 'date-fns';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
  IonPage,
  IonLabel,
  IonButton,
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'Child',
  components: {
    IonPage,
    IonLabel,
    IonButton,
    IonButtons,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent
  },
  setup(): any {
    const store = useStore();
    const route = useRoute();
    const childId = Number(route.params.id) || 0;
    const child = store.getters['user/childById'](childId);
    const age = function(age = '') {
      if (age === '') {
        return '';
      }

      try {
        return formatDistance(parseISO(age), new Date()) + ' old';
      } catch (err) {
        console.log(err);
      }
    };

    const dateFormatted = (date = '') => {
      try {
        return format(parseISO(date), 'hh:mm / MMMM d / yyyy');
      } catch (err) {
        return null;
      }
    };

    const hasAppointment = computed(() => dateFormatted(child.nextAppointment));

    const downloadCSV = async function() {
      const url = `wp-json/user/v2/child/${childId}`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${child.initials}-full-download.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    };

    return {
      chevronBackOutline,
      hasAppointment,
      downloadCSV,
      dateFormatted,
      age,
      child,
      childId
    };
  }
};
</script>
